import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Navbar, Spinner } from "@material-tailwind/react";
import Loading from "../components/Loading";
import Login from "../components/Login";
import { ComplexNavbar } from "../components/ComplexNavbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CaseVariant } from "../components/steps/Case";
import { Build, Cart, CartCheckoutData, CartResponse, CartResponseItem, Case, MinimumRequirements } from "../types";
import CartItem from "../components/CartItem";
import { Footer } from "../components/Footer";
import CartNotExists from "../components/CartNotExists";
import { useCart } from "../contexts/CartContext";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";

function CartPage() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [cartData, setCartData] = useState<CartResponse>();
  const [cartNotExists, setCartNotExists] = useState<boolean>(false);

  const [minimumRequirements, setMinimumRequirements] = useState<MinimumRequirements[]>([]);

  const [totalPrice, setTotalPrice] = useState<number>(0);

  const cartID = searchParams.get("cartID") as string;

  const [emptyCart, setEmptyCart] = useState<boolean>(false);

  const { setItemCount } = useCart();

  // Function to fetch the cart data
  const getCartData = (cartID: string) => {            
    const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";

    if (!cartID || cartID==="" || cartID === " ") {
      setCartNotExists(true);
      return;
    }

    const handleAPICall = async () => {
      try {
        const response = await axios.get(`${apiURL}/cart/${cartID}`);
        setCartData(response.data.data);

        localStorage.setItem('cartCount', ""+response.data.data?.items.length);
        setItemCount(response.data.data?.items.length);

        let newTotalPrice = 0;
        (response.data.data.items as CartResponseItem[]).forEach(cartItem => {
            newTotalPrice += cartItem.json.price;
        })
        setTotalPrice(newTotalPrice);
      } catch (err) {
        setCartNotExists(true);
      }
    };
    handleAPICall();
  }

  useEffect(() => {
    getCartData(cartID);
  }, [cartID]);

  // Callback function to re-fetch cart data after an item is deleted
  const handleItemDeleted = (price: number) => {
    getCartData(cartID); 
  };

  useEffect(() => {
    if (cartData?.items.length === 0) {
        setEmptyCart(true);
        localStorage.setItem('cartCount', '0');
    }
  }, [cartData]);

  const checkout = () => {
    navigate('/checkout');
  }

  if (cartNotExists) {
    return (
      <CartNotExists cartID={cartID}/>
    )
    
  }

  if (!cartData) {
    return <Loading />;
  }

  return (
    <div className="">

      <div className="hidden bg-white/80 backdrop-blur-sm fixed bottom-8 z-50 p-2 4xl:flex justify-between items-center shadow-md rounded-full" style={{ left: 'calc(calc(0.5 * calc(100%)) - 270px)', width: 540 }}>
            <div className="flex w-full text-black items-center justify-center gap-4">
                <button onClick={() => {}} className="px-4 py-2 text-content rounded-lg hover:bg-content/10 transition-all flex flex-row gap-2 items-center">
                    <PlusIcon width={20} height={20}/> <span>Add Student</span>
                </button>
                <button onClick={() => {}} className={`px-4 py-2 rounded-lg transition-all flex flex-row gap-2 items-center`}>
                    <TrashIcon width={20} height={20}/>  <span>Delete Selected</span>
                </button>
            </div>
        </div>


      <div className='flex justify-center xl:pt-4'>
        <ComplexNavbar />
      </div>
      <div className="xl:mt-4 flex flex-col items-center justify-center h-full">
        <div className="max-w-screen-xl w-full px-4 h-full mt-4">
          <div className="font-medium text-[24px]">Cart</div>
          <div className="mt-2 flex flex-col">
            {cartData && cartData.items && (
              <div className="mt-4 flex flex-col gap-4">
                {cartData.items.map(item => (
                  <CartItem 
                    key={item.itemid}
                    cartID={cartID} 
                    responseItem={item}
                    onDelete={handleItemDeleted}  // Pass the callback to each CartItem
                  />
                ))}
              </div>
            )}
            {emptyCart &&
            <>
                <div className="w-full flex flex-col gap-2 items-center justify-center text-[#0d0d0d]">
                    <div>There's nothing here...</div>
                    <Button onClick={() => {navigate("/");}}>Add Something</Button>
                </div>
            </>
            }
            {!emptyCart && <>
                <div className="ml-auto mt-4">
                  <div className="text-2xl"><span className="font-medium">Total Price:</span> ${totalPrice}</div>
                </div>
                <div className="mt-4 ml-auto">
                    <Button size="lg" onClick={checkout}>Checkout</Button>
                </div>
            </>}
          </div>
        </div>
      </div>
      <div className="mt-8 w-full border-[#21211] border-t-2">
        <div className="mt-4">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default CartPage;
