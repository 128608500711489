import React, { useEffect, useState } from 'react';
import { Input, Select, Typography, Option, Textarea, Checkbox, Button } from "@material-tailwind/react";
import { ComplexNavbar } from "../components/ComplexNavbar";
import { Footer } from "../components/Footer";
import ReCAPTCHA from "react-google-recaptcha"; // Google reCAPTCHA
import axios from 'axios';
import SuccessDialog from '../components/submit/SuccessDialog';

const Contact: React.FC = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [enquiry, setEnquiry] = useState("");
    const [message, setMessage] = useState("");
    const [agreed, setAgreed] = useState(false);
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [formError, setFormError] = useState("");

    const [success, setSuccess] = useState<boolean>(false);

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!name || !email || !message || !agreed || !captchaToken) {
            setFormError("Please fill all fields, agree to the terms, and complete the CAPTCHA.");
            return;
        }

        let natureOfEnquiry = "N/A";
        if (enquiry && enquiry !== "") {
            natureOfEnquiry = enquiry;
        }

        // Submit form data (Here you can use axios to post the form data to your backend)
        const formData = {
            name,
            email,
            natureOfEnquiry,
            message,
            captchaToken
        };

        const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";
        const response = await axios.post(`${apiURL}/contact`, formData);

        if (response.data.success) {
            console.log('Form submitted successfully:', response.data.message);

            setSuccess(true);
        } else {
            console.error('Form submission error:', response.data.message);
        }

        // Reset form after successful submission
        setName("");
        setEmail("");
        setEnquiry("");
        setMessage("");
        setAgreed(false);
    };

    const handleCaptcha = (token: string | null) => {
        setCaptchaToken(token);
    };

    const closeSuccessDialog = () => {
        setSuccess(false)
    }

    return (
        <div className="xl:mt-4 flex flex-col items-center justify-center h-full">
            <ComplexNavbar />

            <SuccessDialog open={success} onClose={() => {closeSuccessDialog()}}/>

            {/* CONTENT */}
            <div className={`max-w-screen-xl w-full h-full mt-8 px-8 2xl:px-0`}>
                <div className="grid grid-cols-2 gap-16">
                    <div className="flex flex-col text-[#212121]">
                        <div className="text-3xl font-bold ">Contact Form</div>
                        <div className="text-[16px] mt-2">
                            Have questions, concerns or need a quote? Don’t hesitate to reach out. Our dedicated support team will answer you within 24 hours.
                        </div>

                        <form onSubmit={handleSubmit} className="mt-[30px] text-[#263238] ">
                            <div className="max-w-[384px] flex flex-col gap-[20px]">
                                <div className="flex flex-col gap-[12px]">
                                    <div className="text-[16px] font-bold">Contact Details</div>
                                    <Input 
                                        placeholder="Name" 
                                        label="Name" 
                                        value={name} 
                                        onChange={(e) => setName(e.target.value)} 
                                        required
                                    />
                                    <Input 
                                        placeholder="me@gmail.com" 
                                        label="Email Address" 
                                        value={email} 
                                        onChange={(e) => setEmail(e.target.value)} 
                                        required
                                    />
                                </div>
                                <div className="flex flex-col gap-[12px]">
                                    <div className="text-[16px] font-bold">Nature of Enquiry</div>
                                    
                                    <Select 
                                        label="Select Option" 
                                        value={enquiry} 
                                        onChange={(value) => setEnquiry(value as string)}
                                    >
                                        <Option value="General Enquiry">General Enquiry</Option>
                                        <Option value="Request For Quote">Request For Quote</Option>
                                    </Select>
                                    
                                </div>
                                <div className="flex flex-col gap-[12px]">
                                    <div className="text-[16px] font-bold">Message</div>
                                    <Textarea 
                                        label="Message" 
                                        value={message} 
                                        onChange={(e) => setMessage(e.target.value)} 
                                        resize={true}
                                        required
                                    />
                                </div>
                            </div>
                            
                            <div className="mt-2 ml-[-12px] flex flex-row gap-1 items-center">
                                <Checkbox 
                                    checked={agreed} 
                                    onChange={(e) => setAgreed(e.target.checked)} 
                                    required
                                />
                                <div className="flex text-[#78909C]">
                                    I agree with the&nbsp;
                                    <a
                                        href="/legal/terms-of-service"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="underline text-black"
                                    >
                                        Terms of Service
                                    </a>
                                    &nbsp;and&nbsp;
                                    <a
                                        href="/legal/privacy-policy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="underline text-black"
                                    >
                                        Privacy Policy
                                    </a>.
                                </div>
                            </div>

                            {/* Google reCAPTCHA */}
                            <div className="mt-[15px]">
                                <ReCAPTCHA
                                    sitekey="6LfJiGcqAAAAANKl0-WJwAzq7NRzkJvPtvRql2gZ"  // Replace with your actual site key
                                    onChange={handleCaptcha}
                                />
                            </div>

                            {/* Display error message if any */}
                            {formError && <div className="text-red-600 mt-2">{formError}</div>}

                            <div className="mt-4 max-w-[384px]">
                                <Button type="submit" className="w-1/2">Submit</Button>
                            </div>
                        </form>
                    </div>

                    <img src={"dark-image.png"} className="mt-8 rounded-[60px] h-full" alt="Computer Case" />
                </div>
            </div>

            <div className="mt-32 w-full border-[#21211] border-t-2">
                <div className="mt-4">
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default Contact;
