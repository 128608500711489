import { Auth0Provider } from "@auth0/auth0-react";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import App from "./App";
import { CartProvider } from "./contexts/CartContext";

type Props = { children?: ReactNode };

export const Auth0ProviderWithNavigate: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENTID;
  const redirectUri = process.env.REACT_APP_DEV === 'true' ? "http://localhost:3000/callback" : "https://adriftcomputers.com/callback";
  const audience = process.env.REACT_APP_DEV === 'true' ? "http://localhost:8000" : "https://api.adriftcomputers.com";

  // @ts-ignore
  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    console.error('Missing environment variables');
    return null;
  }

  return (
    <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: redirectUri,
      audience: audience,
      scope: 'openid profile email'
    }}
    onRedirectCallback={onRedirectCallback}
    
    >
      <CartProvider>
        <App/>
      </CartProvider>
    </Auth0Provider>
  );
};