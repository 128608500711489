import React, { useEffect, useState } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  MenuItem,
  IconButton,
  Collapse,
  MenuList,
  Menu,
  MenuHandler,
  Avatar,
  Tooltip,
} from "@material-tailwind/react";
import {
  Bars2Icon,
  HomeIcon,
  IdentificationIcon,
  EnvelopeIcon,
  ChevronDownIcon,
  UserCircleIcon,
  LifebuoyIcon,
  PowerIcon,
  ShoppingCartIcon,
  PlusCircleIcon,
  PlusIcon
} from "@heroicons/react/24/solid";
import { useAuth0 } from "@auth0/auth0-react";
import { useFetcher, useNavigate } from "react-router-dom";
import axios from "axios";
import { useCart } from "../contexts/CartContext";

// nav list component
const navListItems = [
  {
    label: "Home",
    icon: HomeIcon,
    href: "/"
  },
  {
    label: "About Us",
    icon: IdentificationIcon,
    href: "/about"
  },
  {
    label: "Contact",
    icon: EnvelopeIcon,
    href: "/contact"
  },
];

const profileMenuItems = [
  {
    label: "Dashboard",
    icon: UserCircleIcon,
  },
  {
    label: "Help",
    icon: LifebuoyIcon,
  },
  {
    label: "Sign Out",
    icon: PowerIcon
  },
];

interface NavbarProps {
  profile?: string | undefined;
}

const ProfileMenu: React.FC<NavbarProps> = ({ profile }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
 
  const closeMenu = () => setIsMenuOpen(false);
 
  const { logout } = useAuth0();
  const navigate = useNavigate();

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
        >
          <Avatar
            variant="circular"
            size="sm"
            className="border border-gray-900 p-0.5"
            src={profile}
          />
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform ${
              isMenuOpen ? "rotate-180" : ""
            }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-1">
        {profileMenuItems.map(({ label, icon }, key) => {
          const isLastItem = key === profileMenuItems.length - 1;
          return (
            <MenuItem
              key={label}
              onClick={() => {
                if (label.toLowerCase().includes("out")) {
                  logout();
                } else if (label === "Dashboard") {
                  navigate("/account");
                } else if (label === "Help") {
                  navigate("/contact");
                } else {
                  closeMenu();
                }
              }}
              className={`flex items-center gap-2 rounded ${isLastItem
                  ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
                  : ""}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
              {React.createElement(icon, {
                className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                strokeWidth: 2,
              })}
              <Typography
                as="span"
                variant="small"
                className="font-normal"
                color={isLastItem ? "red" : "inherit"}
              >
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}

function NavList() {
  const { user, isLoading } = useAuth0();

  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      }
    });
  };

  const handleRegister = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
      authorizationParams: {
        screen_hint: "signup"
      }
    });
  };

  const { logout } = useAuth0();
  const navigate = useNavigate();

  return (
    <ul className="mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
      {navListItems.map(({ label, icon, href }, key) => (
        <Typography
          key={label}
          as="a"
          href={href}
          variant="small"
          color="gray"
          className="font-medium text-blue-gray-500"
        >
          <MenuItem className="flex items-center gap-2 lg:rounded-full">
            {React.createElement(icon, { className: "h-[18px] w-[18px]" })}{" "}
            <a className="text-gray-900" href={href}> {label}</a>
          </MenuItem>
        </Typography>
      ))}
      {(!user && (!isLoading)) && (
        <div className="lg:hidden">
          <div className="w-full bg-blue-gray-100 h-[1px] mb-2"></div>

          <Typography
            variant="small"
            color="gray"
            className="font-medium text-blue-gray-500"
          >
            <MenuItem className="flex items-center gap-2 lg:rounded-full" onClick={handleLogin}>
              {React.createElement(UserCircleIcon, { className: "h-[18px] w-[18px]" })}{" "}
              <a className="text-gray-900" href={""}>Login</a>
            </MenuItem>
          </Typography>
          <Typography
            variant="small"
            color="gray"
            className="font-medium text-blue-gray-500"
          >
            <MenuItem className="flex items-center gap-2 lg:rounded-full" onClick={handleRegister}>
              {React.createElement(PlusIcon, { className: "h-[18px] w-[18px]" })}{" "}
              <a className="text-gray-900" href={""}>Register</a>
            </MenuItem>
          </Typography>
        </div>
      )}
      {(user && !isLoading) && (
        <div className="lg:hidden">
          <div className="w-full bg-blue-gray-100 h-[1px] mb-2"></div>
          <Typography
            variant="small"
            color="gray"
            className="font-medium text-blue-gray-500"
          >
            <MenuItem className="flex items-center gap-2 lg:rounded-full" onClick={() => {navigate("/account")}}>
              {React.createElement(UserCircleIcon, { className: "h-[18px] w-[18px]" })}{" "}
              <a className="text-gray-900" href={""}>Dashboard</a>
            </MenuItem>
          </Typography>
          
          <Typography
            variant="small"
            color="gray"
            className="font-medium text-blue-gray-500 mt-2"
          >
            <MenuItem className="flex items-center gap-2 lg:rounded-full hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10 text-red-500" onClick={() => {logout()}}>
              {React.createElement(PowerIcon, { className: "h-[18px] w-[18px]" })}{" "}
              <a className="text-gray-900" href={""}>Log Out</a>
            </MenuItem>
          </Typography>
          
        </div>
        
        
      )}
    </ul>
  );
}

export function ComplexNavbar() {
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const navigate = useNavigate();
  const { user, isLoading } = useAuth0();

  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);

  const { itemCount } = useCart();

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false)
    );
  }, []);

  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      }
    });
  };

  const handleRegister = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
      authorizationParams: {
        screen_hint: "signup"
      }
    });
  };

  // Handle caching user profile picture
  let cachedProfile = "https://www.ledr.com/colours/white.jpg";

  if (localStorage.getItem("profilePicture")) {
    cachedProfile = localStorage.getItem("profilePicture") as string;
    console.log("Profile is cached")
  }
  const [profilePicture, setProfilePicture] = useState<string>(cachedProfile);
  
  const goToCart = () => {
    console.log("Going to Cart?");
    if (localStorage.getItem('cartID') && localStorage.getItem('cartID') !== "") {
      console.log(`Stored CartID Found: ${localStorage.getItem('cartID')}`)
      navigate(`/cart?cartID=${localStorage.getItem('cartID')}`)
    }
    
  }

  useEffect(() => {
    if (!isLoading && user && user.picture) {
      setProfilePicture(user.picture);
      localStorage.setItem("profilePicture", user.picture)
    } else if (!isLoading) {
      setProfilePicture("https://i.pinimg.com/originals/ac/11/aa/ac11aa2add3b0193c8769e0a17d13535.jpg");
    }
  }, [user, isLoading])


  return (
    <Navbar className="sticky top-0 xl:top-3 z-50 bg-white mx-auto max-w-screen-xl p-2 lg:rounded-full lg:pl-6">
      <div className="relative mx-auto flex items-center justify-between text-"></div>
      
      <div className="absolute xl:top-3 left-6 p-2">
        <a href="/">
          <img src={"/Banner.png"} alt="AdriftComputers" width={188} />
        </a>
      </div>
      <div className="mx-auto flex items-center justify-center text-blue-gray-900">
        <div className="hidden lg:block">
          <NavList />
        </div>
        <IconButton
          size="sm"
          color="blue-gray"
          variant="text"
          onClick={toggleIsNavOpen}
          className="ml-auto mr-2 lg:hidden"
        >
          <Bars2Icon className="h-6 w-6" />
        </IconButton>

        <div className={`absolute right-12 ${(!isLoading && user) ? 'lg:right-20' : 'lg:right-44'} p-2`}>
            <IconButton
              size="sm"
              variant="text"
              onClick={goToCart}
              disabled={itemCount <= 0}
            >
              <ShoppingCartIcon className="h-6 w-6" />
            </IconButton>
        </div>

        {!isLoading &&
          <div className="hidden lg:block lg:absolute right-0 p-2">
            {user ? 
            <>
            <div className="">
              <ProfileMenu profile={profilePicture}/>
            </div>
            </> 
            : 
            <div className="flex flex-row gap-2 pr-2">
              <Button variant="text" size="sm" onClick={handleLogin}>Login</Button>
              <Button variant="gradient" size="sm" onClick={handleRegister}>Sign Up</Button>
            </div>
            }
          </div>
        }
      </div>
      
      <Collapse open={isNavOpen} className="overflow-scroll">
        <NavList />
      </Collapse>
    </Navbar>
  );
}
