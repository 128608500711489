
import { Route, Routes } from 'react-router-dom';
import Games from './pages/Games';
import CreateBuild from './pages/CreateBuild';
import TestOrder from './pages/TestOrder';
import ToS from './pages/legal/ToS';
import PrivacyPolicy from './pages/legal/Privacy';
import Returns from './pages/legal/Returns';
import Warranty from './pages/legal/Warranty';
import Contact from './pages/Contact';
import Profile from './pages/AccountPage';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import { ToastContainer } from 'react-toastify';

function App() {

  return (
    <>
    <ToastContainer />
    <Routes>
      <Route path="/" element={ <CreateBuild/> } />
      <Route path="/contact" element={ <Contact/> } />
      <Route path="/account" element={ <Profile/> } />
      <Route path="/cart" element={ <CartPage/> } />
      <Route path="/checkout" element={ <CheckoutPage/> } />
      

      <Route path="/legal/terms-of-service" element={ <ToS/> } />
      <Route path="/legal/privacy-policy" element={ <PrivacyPolicy/> } />
      <Route path="/legal/returns-policy" element={ <Returns/> } />
      <Route path="/legal/warranty" element={ <Warranty/> } />

      <Route path="/test" element={ <TestOrder/> } />
    </Routes>
    </>
    
  )
}

export default App;
