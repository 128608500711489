import { Button, Spinner } from "@material-tailwind/react"
import { ComplexNavbar } from "./ComplexNavbar";
import { useNavigate } from "react-router-dom";

interface CartNotExistsProps {
  cartID: string;
}

const CartNotExists: React.FC<CartNotExistsProps> = ({cartID}) => {
    const navigate = useNavigate();

    return (
      <div className="h-screen">
        <div className='flex justify-center xl:pt-4'>
          <ComplexNavbar />
        </div>
        <div className="w-full h-full mt-[-64px] flex flex-col items-center justify-center">
            <div className="text-[64px] font-bold">ERROR 404</div>
            <div className="mt-[-10px]">The cart with ID <span className="font-medium">{cartID}</span> does not exist!</div>

            <Button className="mt-4" onClick={() => navigate('/')}>Return Home</Button>
        </div>
      </div>
    )
}

export default CartNotExists;