import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

interface CartContextType {
  itemCount: number;
  setItemCount: React.Dispatch<React.SetStateAction<number>>;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

const apiURL = process.env.REACT_APP_DEV
  ? "http://localhost:8000"
  : "https://api.adriftcomputers.com";

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [itemCount, setItemCount] = useState(0);

  useEffect(() => {
    // Function to initialize the cart data
    const initializeCart = async () => {
      const cartCount = localStorage.getItem("cartCount");
      const cartID = localStorage.getItem("cartID");

      if (cartCount) {
        setItemCount(Number(cartCount)); // Set the count from localStorage
      } else if (cartID && cartID !== "") {
        try {
          const response = await axios.get(`${apiURL}/cart/${cartID}`);
          const itemsLength = response.data.data?.items.length || 0;
          localStorage.setItem("cartCount", itemsLength.toString()); // Cache the count in localStorage
          setItemCount(itemsLength);
        } catch (err) {
          console.error("Error fetching cart data:", err);
        }
      }
    };

    initializeCart();
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <CartContext.Provider value={{ itemCount, setItemCount }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
