import { Button, Card } from "@material-tailwind/react";
import { TrashIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import axios from "axios";
import { Build, CartDisplayItemJSON, CartResponseItem } from "../types";

interface CartItemProps {
  cartID: string;
  responseItem: CartResponseItem; 
}

const CheckoutItem: React.FC<CartItemProps> = ({ cartID, responseItem }) => {
  const [item, setItem] = useState<CartDisplayItemJSON>();

  useEffect(() => {
    if (responseItem) setItem(responseItem.json);
  }, [responseItem]);

  const [build, setBuild] = useState<Build>();
  
  useEffect(() => {
    const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";

    if (!item)
        return;

    const handleClick = async () => {
        try {
            const response = await axios.post(`${apiURL}/build/price`, {
                min: item?.minimumRequirements,
                case: item?.selectedCase,
                variant: item?.caseVariant,
            }
            );
            setBuild(response.data.build);
            console.log(`Calling price update for itemid ${responseItem.itemid}`, response.data.build.price)
        } catch (err) {
        console.error('Error getting build price:', err);
        }
    };
    handleClick();
  }, [item])
    

  if (!item ) {
    return <div></div>;
  }

  return (
    <Card className="flex flex-col 2xl:flex-row text-[#212121] 2xl:items-center mx-4 px-4 2xl:px-0">
      <div className="flex 2xl:hidden font-semibold text-[20px] mt-4 text-left">{item.selectedCase.name}</div>
      <img src={item.caseVariant.images[0]} width={170} alt="Case Variant" className="hidden 2xl:flex p-4 h-fit " />
      <div className="2xl:hidden flex w-full 2xl:flex-none items-center justify-center">
        <img src={item.caseVariant.images[0]} width={170} alt="Case Variant" className="p-4 h-fit " />
      </div>

      <div className="flex flex-col">
        <div className="hidden 2xl:flex font-semibold text-[20px] mt-4 text-left">{item.selectedCase.name}</div>
        <div className="font-medium text-[16px]">Case Variant: <span className="font-normal">{item.caseVariant.name}</span></div>
        <div className="mt-4"/>
        <div className="grid grid-cols-2 grid-rows-2 gap-x-4 gap-y-2">
          <div className="font-medium text-[16px]">CPU: <span className="font-normal">{item.parts.cpu}</span></div>
          <div className="font-medium text-[16px]">GPU: <span className="font-normal">{item.parts.gpu}</span></div>
          <div className="font-medium text-[16px]">RAM: <span className="font-normal">{item.minimumRequirements.ram}GB</span></div>
          <div className="font-medium text-[16px]">Storage: <span className="font-normal">{item.minimumRequirements.storage}TB</span></div>
        </div>
        <div className="mt-4 mb-2 text-xl"><span className="font-semibold ">Price:</span> ${responseItem.json.price}</div>
      </div>
    </Card>
  );
};

export default CheckoutItem;
