import { Card } from "@material-tailwind/react"
import { Case, CaseVariant } from "./steps/Case"
import { useEffect, useState } from "react";

interface CaseCardProps {
    currentSelection: CaseVariant | undefined;
    caseData: Case;
}

const CaseCard: React.FC<CaseCardProps> = ({currentSelection, caseData}) => {
    const variant: CaseVariant = caseData.variants[0];

    const [price, setPrice] = useState<string>(String(variant.price));
    const [symbol, setSymbol] = useState<string>("$");

    useEffect(() => {
        if (currentSelection) {
            if (caseData.variants.includes(currentSelection)) {
                setSymbol("");
                setPrice("Current Selection")
                return;
            }
            if (currentSelection?.price > variant.price) {
                setSymbol("-$");
            } else {
                setSymbol("+$");
            }

            let _price = currentSelection.price - variant.price;
            if (_price < 0) {
                _price*=-1;
            }
            _price = Math.round(_price * 100) / 100;
            setPrice(String(_price));
        }
    }, [currentSelection])

    return (
        <Card className="w-[256px] flex flex-col items-center justify-center text-[#212121] cursor-pointer">
            <img src={variant.images[0]} width={170} className="mt-4"></img>
            <div className="font-bold text-[16px] mt-4 px-4 text-center">{caseData.name}</div>
            <div className="font-normal text-[12px] mb-4">{symbol}{price}</div>
        </Card>
    )
}

export default CaseCard;