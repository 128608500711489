import { Button, Card } from "@material-tailwind/react";
import { TrashIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import axios from "axios";
import { Build, CartDisplayItemJSON, CartResponseItem } from "../types";
import { toast } from "react-toastify";

interface CartItemProps {
  cartID: string;
  responseItem: CartResponseItem;  // Assuming `CartResponseItem` type here
  onDelete: (price: number) => void;  
}

const CartItem: React.FC<CartItemProps> = ({ cartID, responseItem, onDelete }) => {
  const [item, setItem] = useState<CartDisplayItemJSON>();

  const [deleted, setDeleted] = useState<boolean>(false);

  useEffect(() => {
    if (responseItem) setItem(responseItem.json);
  }, [responseItem]);

  const removeSelf = () => {
    const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";
    
    const handleAPICall = async () => {
      setDeleted(true); // hide self
      try {
        const response = await axios.post(`${apiURL}/cart/item/delete`, { cartID, cartItemID: responseItem.itemid });
        
        if (response.status === 200) {
          
          onDelete(build?.price || 0);  // Call the onDelete callback to trigger a re-fetch
        }
      } catch (err) {
        console.error('Error deleting item:', err);
      }
    };

    toast.promise(handleAPICall(), {
      pending: 'Deleting Item From Cart',
      success: "Item Deleted From Cart",
      error: "An Error Has Occured"
    })
  };

  const [build, setBuild] = useState<Build>();
    

  if (!item ) {
    return <div></div>;
  }

  return (
    <Card className="flex flex-col 2xl:flex-row text-[#212121] 2xl:items-center mx-4 px-4 2xl:px-0">
      <div className="flex 2xl:hidden font-semibold text-[20px] mt-4 text-left">{item.selectedCase.name}</div>

      <img src={item.caseVariant.images[0]} width={170} alt="Case Variant" className="hidden 2xl:flex p-4 h-fit " />
      <div className="2xl:hidden flex w-full 2xl:flex-none items-center justify-center">
        <img src={item.caseVariant.images[0]} width={170} alt="Case Variant" className="p-4 h-fit " />
      </div>

      <div className="flex flex-col w-full">
        <div className="hidden 2xl:flex font-semibold text-[20px] mt-4 text-left">{item.selectedCase.name}</div>
        <div className="font-medium text-[16px]">Case Variant: <span className="font-normal">{item.caseVariant.name}</span></div>
        <div className="mt-4"/>
        <div className="grid grid-cols-2 grid-rows-2 gap-x-4 gap-y-2 w-1/2">
          <div className="font-medium text-[16px]">CPU: <span className="font-normal">{item.parts.cpu}</span></div>
          <div className="font-medium text-[16px]">GPU: <span className="font-normal">{item.parts.gpu}</span></div>
          <div className="font-medium text-[16px]">RAM: <span className="font-normal">{item.minimumRequirements.ram}GB</span></div>
          <div className="font-medium text-[16px]">Storage: <span className="font-normal">{item.minimumRequirements.storage}TB</span></div>
        </div>
        <div className="w-full flex flex-row items-center mt-4 mb-4 ">
          <div className=" text-xl"><span className="font-semibold ">Price:</span> ${responseItem.json.price}</div>
          <button className="flex flex-row items-center ml-auto mr-6 text-red-500 gap-1.5 hover:bg-red-500/10 transition-all p-2 rounded-lg" onClick={removeSelf}>
            <TrashIcon className="h-4 mt-[-2px]" /> Remove
          </button>
        </div>
      </div>
    </Card>
  );
};

export default CartItem;
