import { useEffect, useState } from "react";
import { ComplexNavbar } from "../components/ComplexNavbar";
import { Footer } from "../components/Footer";
import Checkout from "../components/steps/Checkout";
import NewCheckout from "../components/NewCheckout";
import { CartCheckoutData, CartResponse, CustomerInformation } from "../types";
import axios from "axios";
import Loading from "../components/Loading";
import CartItem from "../components/CartItem";
import CheckoutItem from "../components/CheckoutItem";

const CheckoutPage: React.FC = () => {
    const [cartData, setCartData] = useState<CartResponse>();
    const [cartID, setCartID] = useState<string>();

    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [updatedItems, setUpdatedItems] = useState<Set<string>>(new Set());

    useEffect(() => {
        if (localStorage.getItem('cartID') && localStorage.getItem('cartID') !== "") {
            setCartID(localStorage.getItem('cartID') as string);
        }
    }, [])

    const getCartData = (cartID: string) => {            
        const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";
    
        if (!cartID || cartID==="" || cartID === " ") {
          return;
        }
    
        const handleAPICall = async () => {
          try {
            const response = await axios.get(`${apiURL}/cart/${cartID}`);
            setCartData(response.data.data);
          } catch (err) {
          }
        };
        handleAPICall();
    }
      
    useEffect(() => {
        if (cartID)
            getCartData(cartID);
    }, [cartID])

    const handlePriceUpdate = (itemID: string, price: number) => {
        if (!updatedItems.has(itemID)) {
            setTotalPrice(prevTotal => prevTotal + price);
            setUpdatedItems(prev => new Set(prev.add(itemID)));
        }
    };

    const onPurchase = (customer: CustomerInformation) => {
        const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";

        const handleAPI = async () => {
            try {
                const data: CartCheckoutData[] = [];
                cartData?.items.forEach(item => data.push({min: item.json.minimumRequirements, variant: item.json.caseVariant, selectedCase: item.json.selectedCase}));

                const response = await axios.post(`${apiURL}/cart/checkout`, {checkoutData: data, customer: customer});
                console.log(response);
                if (response.data.url) {
                    window.location.href = response.data.url;
                }
            } catch (err) {
            console.error('Error creating checkout session:', err);
            }
        };
        handleAPI();
    }

    if (!cartData) {
        return <Loading/>
    }

    return (
        <div className="xl:mt-4 flex flex-col items-center justify-center h-full">
    <ComplexNavbar />

    <div className="mt-4 xl:mt-8 flex flex-col-reverse xl:flex-row gap-4">
        <div className="flex flex-col gap-4">
            {cartData.items.map(item => (
                <CheckoutItem 
                    key={item.itemid}
                    cartID={cartID as string}
                    responseItem={item} onPriceUpdate={handlePriceUpdate}                />
            ))}
        </div>
        {/* Set a max height to the sticky element's parent */}
        <div className="relative xl:sticky xl:top-16 xl:h-[calc(100vh-100px)]">
            <NewCheckout onPurchase={onPurchase} price={totalPrice}/>
        </div>
    </div>

    <div className="mt-8 w-full border-[#21211] border-t-2">
        <div className="mt-4">
        <Footer />
        </div>
    </div>
</div>
    )
}

export default CheckoutPage;